<template>
  <div id="merge">
    <nav-bar :title="$t('merge.page_title')" />

    <section v-if="fileList.isEmpty()" class="container">
      <h1>{{ $t('merge.merging.header') }}</h1>
      <p>{{ $t('merge.merging.description') }}</p>

      <open-file :multiple="true" @select-files="onFilesSelected"/>
    </section>

    <section v-else-if="downloadLink" class="container">
      <h1>{{ $t('merge.completed.header') }}</h1>

      <p>
        {{ $t('merge.completed.download_didnt_start') }}
        <a class="btn-inline" :href="downloadLink" download="merged.pdf">{{ $t('merge.completed.click_here') }}</a>.
      </p>

      <p>
        {{ $t('merge.completed.after_this') }}
        <button class="btn-inline" type="button" @click="reset">{{ $t('merge.completed.start_over') }}</button> {{ $t('or') }} 
        <router-link to="/" class="btn-inline">{{ $t('merge.completed.go_to_dashboard') }}</router-link>.
      </p>
    </section>

    <section v-else class="container">
      <h1>{{ $t('merge.merging.header') }}</h1>
      <p>{{ $t('merge.merging.description') }}</p>

      <div class="file-box">
        <div class="view-options">
          <div class="dropdown">
            <button>{{ $t('merge.merging.view') }}</button>

            <div class="dropdown-menu">
              <button @click="view = 'list'">
                <span class="icon-holder">
                  <i class="las la-list"></i>
                </span>
                <span>{{ $t('merge.merging.view_actions.list') }}</span>
              </button>

              <button @click="view = 'grid'">
                <span class="icon-holder">
                  <i class="las la-th"></i>
                </span>
                <span>{{ $t('merge.merging.view_actions.grid') }}</span>
              </button>
            </div>
          </div>
        </div>

        <div :class="`files file-${view}`">
          <draggable v-model="fileList.files" tag="transition-group" handle=".handle" ghost-class="ghost" item-key="id" :animation="200">
            <template #item="{element: file}">
              <div class="file">
                <button v-if="view === 'list'" class="btn-inline handle">
                  <i class="las la-grip-lines"></i>
                </button>

                <div v-else class="actions">
                  <button class="btn-inline handle">
                    <i class="las la-arrows-alt"></i>
                  </button>

                  <button class="btn-inline" @click="fileList.removeItem(file.id)">
                    <i class="las la-times"></i>
                  </button>
                </div>

                <div class="preview">
                  <img :src="file.previewUrl">
                </div>

                <div class="info">
                  <span class="title">{{file.original.name}}</span>
                </div>

                <div v-if="view === 'list'" class="actions">
                  <button class="btn-inline" @click="file.rotate(-1)">
                    <i class="las la-undo-alt"></i>
                  </button>
                  <button class="btn-inline" @click="file.rotate(1)">
                    <i class="las la-redo-alt"></i>
                  </button>
                </div>

                <button v-if="view === 'list'" class="btn-inline" @click="fileList.removeItem(file.id)">
                  <i class="las la-times"></i>
                </button>
              </div>
            </template>
          </draggable>
        </div>

        <button @click="addFiles">{{ $t('merge.merging.add_files') }}</button>

        <div class="merge-actions">
          <div class="action">
            <button class="btn-primary btn-large">{{ $t('merge.merging.merge_and_edit') }}</button>
          </div>
          <div class="action">
            <button class="btn-primary btn-large" @click="mergeAndSave">{{ $t('merge.merging.merge_and_save') }}</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import OpenFile from '@/components/OpenFile.vue';
import Draggable from 'vuedraggable';
import PdfFileList from '@/PdfFileList.js';
import PdfMerger from '@/PdfMerger.js';
import DownloadService from '@/DownloadService.js';
import UploadService from '@/UploadService.js';

export default {
  name: 'merge',
  components: {
    NavBar,
    OpenFile,
    Draggable,
  },
  data() {
    return {
      view: 'list',
      fileList: new PdfFileList(),
      downloadLink: '',
    }
  },
  methods: {
    onFilesSelected(pdfFileList) {
      this.fileList = pdfFileList;

      this.fileList.files.forEach(pdf => pdf.getPreviewUrl());
    },
    async addFiles() {
      const fileListToAppend = await UploadService.uploadPdfFiles(true);

      this.fileList.appendPdfFileList(fileListToAppend);

      this.fileList.files.forEach(pdf => pdf.getPreviewUrl());
    },
    async mergeAndSave() {
      const mergedBlob = await PdfMerger.mergePdfFileList(this.fileList);

      this.downloadLink = DownloadService.downloadBlob(mergedBlob, 'merged.pdf');
    },
    reset() {
      this.fileList = new PdfFileList();

      if(this.downloadLink !== '') URL.revokeObjectURL(this.downloadLink);
      this.downloadLink = '';
    },
  },
  beforeUnmount() {
    if(this.downloadLink !== '') URL.revokeObjectURL(this.downloadLink);
  }
}
</script>

<style scoped>
.file-box {
  background-color: var(--light-color);
  border-radius: .5rem;
  padding: .75rem 1rem 0;
  color: var(--text-default-color);
  border: 2px solid var(--light-color);
}

.view-options {
  margin-bottom: .25rem;
}

.files .file.ghost {
  opacity: .4;
}

.files .file {
  padding: .5rem 1rem;
  border-radius: .5rem;
  margin-bottom: .25rem;
  transition: background-color .2s ease;
}

.files .file:hover {
  background-color: var(--light-hover-color);
}

.files .file button {
  font-size: 1.2rem;
}

.files .file .info {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.files .file .preview img {
  max-width: 100%;
  max-height: 100%;
  border: 1px solid var(--border-light-color);
  border-radius: .2rem;
}

@media (hover: hover) {
  .files .file .actions {
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s ease, visibility .2s ease;
  }

  .files .file:hover .actions {
    visibility: visible;
    opacity: 1;
  }
}

.file-list .file {
  display: flex;
  align-items: center;
}

.file-list .file .preview {
  flex: 0 0 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 .75rem;
}

.file-list .file .info {
  flex-grow: 1;
  min-width: 0;
}

.file-list .file .actions {
  display: flex;
  margin: 0 .75rem;
}

.file-list .file .actions > :not(:last-child) {
  margin-right: .75rem;
}

.file-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  column-gap: .25rem;
}

.file-grid .file {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-grid .file .preview {
  height: 8rem;
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 .75rem;
}

.file-grid .file .info {
  width: 100%;
  text-align: center;
}

.file-grid .file .actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.merge-actions {
  display: flex;
  margin: .25rem -.5rem 0;
  padding: .75rem 0 .75rem;
  background-color: var(--light-color);
  position: sticky;
  bottom: 0;
  flex-wrap: wrap;
}

.merge-actions .action {
  flex: 0 0 100%;
  min-width: 0;
  padding: 0 .5rem;
}

.merge-actions .action + .action {
  margin-top: .5rem;
}

@media (min-width: 576px) {
  .merge-actions .action {
    flex: 0 0 50%;
    margin: 0!important;
  }
}

.merge-actions button {
  width: 100%;
  height: 100%;
}
</style>