import { PDFDocument } from 'pdf-lib';

export default class PdfMerger {

    static async mergePdfFileList(pdfFileList) {
        if(pdfFileList.isEmpty()) return;

        const mergedPdf = await PDFDocument.create();

        for(const pdfFile of pdfFileList.files) {
            const donorPdf = await PDFDocument.load(pdfFile.uint8array);
            const totalPages = donorPdf.getPageCount();

            for(let page = 0; page < totalPages; page++) {
                const [donorPage] = await mergedPdf.copyPages(donorPdf, [page]);

                mergedPdf.addPage(donorPage);
            }
        }

        const uint8array = await mergedPdf.save();

        return new Blob([uint8array], {type: 'application/pdf'});
    }
}