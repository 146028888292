export default class DownloadService {
    
    static downloadBlob(blob, fileName) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.style = 'display: none';
        link.click();
        link.remove();

        return url;
    }
}